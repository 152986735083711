import { IUser } from 'lc-interfaces';

export enum ENotificationType {
  FRIEND_REQUEST = 'FRIEND_REQUEST',
  FRIEND_REQUEST_ACCEPTED = 'FRIEND_REQUEST_ACCEPTED',

  PAYMENT_DEPOSIT_PENDING = 'PAYMENT_DEPOSIT_PENDING',
  PAYMENT_DEPOSIT_SUCCESS = 'PAYMENT_DEPOSIT_SUCCESS',
  PAYMENT_WITHDRAWAL_PENDING = 'PAYMENT_WITHDRAWAL_PENDING',
  PAYMENT_WITHDRAWAL_SUCCESS = 'PAYMENT_WITHDRAWAL_SUCCESS',
}

export interface INotification {
  id: string;
  type: ENotificationType;

  receiver: IUser;
  receiver_id: string;

  sender: IUser;
  sender_id: string;

  payload_id: string;

  created_at: Date;
  deleted_at: Date;
}
