import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { map, Observable } from 'rxjs';

import { IQuery, IResponsePagination, TResponsePagination } from 'lc-interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuctionService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<any[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http.get<IResponsePagination<any[]>>(`@api/auctions`, { params }).pipe(map(response => response.data));
  }

  public auctionsTypesCount(): Observable<any> {
    return this.http.get<IResponsePagination<any[]>>(`@api/auctions/types-count`).pipe(map(response => response.data));
  }

  public auctionsVarietiesCount(): Observable<any> {
    return this.http
      .get<IResponsePagination<any[]>>(`@api/auctions/varieties-count`)
      .pipe(map(response => response.data));
  }

  public auctionsRaritiesCount(): Observable<any> {
    return this.http
      .get<IResponsePagination<any[]>>(`@api/auctions/rarities-count`)
      .pipe(map(response => response.data));
  }
}
