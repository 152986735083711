export enum ECharacterType {
  BARBARIAN = 'BARBARIAN',
  PALADIN = 'PALADIN',
  ROGUE = 'ROGUE',
  WARLOCK = 'WARLOCK',
  WIZARD = 'WIZARD',
}

export enum ECharacterTypeSkin {
  barbarian = 'barbarian',
  dark_elf_3 = 'dark_elf_3',
  succubus = 'succubus',
}

export type TCharacterAnimation =
  | 'dying'
  | 'falling_down'
  | 'hurt'
  | 'idle'
  | 'idle_blinking'
  | 'jump_loop'
  | 'jump_start'
  | 'kicking'
  | 'run_slashing'
  | 'run_throwing'
  | 'running'
  | 'slashing'
  | 'slashing_in_the_air'
  | 'sliding'
  | 'throwing'
  | 'throwing_in_the_air'
  | 'walking';

export interface ICharacter {}

export interface ICharacterCharacteristic {
  health: number;

  physicalDamage: number;
  physicalResistance: number;
  physicalPenetration: number;

  criticalChance: number;
  criticalDamage: number;

  blockChance: number;
  blockStrength: number;

  parryChance: number;

  magicalDamage: number;
  magicalResistance: number;
  magicPenetration: number;

  natureResistance: number;
  darkResistance: number;

  effectResistance: number;
  cooldownReduction: number;
  debuffApplyChance: number;
}
