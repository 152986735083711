import { Component, EventEmitter, Output } from '@angular/core';

import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { InViewportAction, InViewportModule } from 'ng-in-viewport';

@Component({
  selector: 'lc-base-spinner',
  standalone: true,
  imports: [MatProgressSpinnerModule, InViewportModule],
  templateUrl: './base.spinner.html',
  styleUrls: ['base.spinner.scss'],
})
export class BaseSpinner {
  @Output()
  public inViewportAction: EventEmitter<InViewportAction> = new EventEmitter();
}
