<h2 mat-dialog-title class="d-flex align-items-center justify-content-between">
  <div>
    {{ context.title }}
  </div>

  <button mat-icon-button mat-dialog-close class="ms-3 align-content-end">
    <mat-icon> close </mat-icon>
  </button>
</h2>

<mat-dialog-content>
  <ng-container *ngComponentOutlet="component"></ng-container>
</mat-dialog-content>
