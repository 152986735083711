import { NgComponentOutlet } from '@angular/common';
import { Component, inject, Inject, Input, Type } from '@angular/core';

import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { IModal, IModalContext, INotification, IUser } from 'lc-interfaces';

@Component({
  selector: 'lc-base-bottom-sheet',
  standalone: true,
  imports: [NgComponentOutlet, MatBottomSheetModule, MatButtonModule, MatDialogModule, MatIconModule],
  templateUrl: './base.bottom-sheet.html',
  styleUrls: ['./base.bottom-sheet.scss'],
})
export class BaseBottomSheet {
  @Input()
  component!: Type<Component>;

  protected readonly bottomSheetRef = inject(MatBottomSheetRef<BaseBottomSheet>);

  context: IModalContext<INotification | IUser>;

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA)
    public modalData: IModal<INotification | IUser>
  ) {
    this.component = this.modalData.component;
    this.context = this.modalData.context || {};
  }

  close() {
    this.bottomSheetRef.dismiss();
  }
}
