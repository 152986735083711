import { inject, Injectable } from '@angular/core';

import { Wallet } from '@tonconnect/sdk';
import { TonConnectUI, WalletsModalState } from '@tonconnect/ui';

import { BehaviorSubject } from 'rxjs';

import { environment } from 'app/client/environments/environment';

import { UserService } from 'app/client/modules/user/user.service';

@Injectable({
  providedIn: 'root',
})
export class TonService {
  private readonly userService = inject(UserService);

  public tonConnectUI!: TonConnectUI;
  public tonConnectUIWallet$: BehaviorSubject<Wallet | null> = new BehaviorSubject<Wallet | null>(null);

  async initTonConnectUI() {
    this.tonConnectUI = new TonConnectUI({
      manifestUrl: `${environment.TON_MANIFEST_URL}`,
    });

    if (await this.tonConnectUI.connectionRestored) {
      this.tonConnectUIWallet$.next(this.tonConnectUI.connector.wallet);

      if (this.tonConnectUI.connected && !this.userService.user.wallet.address) {
        await this.tonConnectUI.disconnect();

        this.tonConnectUIWallet$.next(null);
      }
    }
  }

  async openTonConnectUIModal() {
    await this.tonConnectUI.openModal();

    this.tonConnectUI.modal.onStateChange((state: WalletsModalState) => {
      if (state.closeReason === 'wallet-selected') {
        this.userService
          .updateMeWallet({
            address: this.tonConnectUI.connector.wallet.account.address,
          })
          .subscribe(() => this.tonConnectUIWallet$.next(this.tonConnectUI.connector.wallet));
      }
    });
  }
}
