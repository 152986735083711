import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';

import { SendTransactionRequest } from '@tonconnect/sdk';

import { map, Observable } from 'rxjs';

import {
  IPayment,
  IQuery,
  IResponse,
  IResponseData,
  IResponsePagination,
  TResponsePagination,
} from 'lc-interfaces';

@Injectable({
  providedIn: 'root',
})
export class PaymentService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<IPayment[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<IResponsePagination<IPayment[]>>(`@api/payments`, { params })
      .pipe(map(response => response.data));
  }

  public findOneById(payment_id: string): Observable<IPayment> {
    return this.http
      .get<IResponseData<IPayment>>(`@api/payments/payment/${payment_id}`)
      .pipe(map(response => response.data));
  }

  public createDeposit(payload: {
    amount: number;
  }): Observable<{ payment: IPayment; transactionRequest: SendTransactionRequest }> {
    return this.http
      .post<
        IResponseData<{ payment: IPayment; transactionRequest: SendTransactionRequest }>
      >(`@api/payments/payment/ton/deposit`, payload)
      .pipe(map(response => response.data));
  }

  public createWithdrawal(payload: { amount: number }): Observable<IPayment> {
    return this.http
      .post<IResponseData<IPayment>>(`@api/payments/payment/ton/withdrawal`, payload)
      .pipe(map(response => response.data));
  }

  public remove(payment_id: string): Observable<IResponse> {
    return this.http.delete<IResponse>(`@api/payments/payment/${payment_id}/remove`);
  }
}
