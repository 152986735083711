import { IUser } from 'lc-interfaces';

export const telegramUserUrl = ({ id, username }: IUser): string => {
  if (username) {
    return `https://t.me/${username}`;
  }

  if (username) {
    return `tg://resolve?domain=${username}`;
  }

  return `tg://user?id=${id}`;
};

export const telegramUserReferralUrl = ({ id, username }: IUser): string => {
  return `t.me/LazyCombatBot?start=${username || id}`;
};
