import { NgComponentOutlet } from '@angular/common';
import { Component, Inject, Input, Type } from '@angular/core';

import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';

import { IModal, IModalContext, INotification, IUser } from 'lc-interfaces';

@Component({
  selector: 'lc-base-dialog',
  standalone: true,
  imports: [NgComponentOutlet, MatDialogModule, MatButtonModule, MatIconModule],
  templateUrl: './base.dialog.html',
  styleUrls: ['./base.dialog.scss'],
})
export class BaseDialog {
  @Input()
  component!: Type<Component>;

  context: IModalContext<INotification | IUser>;

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public modalData: IModal<INotification | IUser>
  ) {
    this.component = this.modalData.component;
    this.context = this.modalData.context || {};
  }
}
