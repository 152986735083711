import { IUser } from 'lc-interfaces';

export interface IPayment {
  id: string;

  external_id: null | string;

  type: EPaymentType;
  status: EPaymentStatus;
  provider: EPaymentProvider;

  amount: string;
  amount_fee: string;
  amount_net: string;
  currency: EPaymentCurrency;

  user: IUser;
  user_id: string;

  created_at: Date;
  updated_at: Date;
}

export enum EPaymentStatus {
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
}

export enum EPaymentType {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL',
}

export enum EPaymentProvider {
  WALLET = 'WALLET',
  AUCTION = 'AUCTION',
  COMMISSION = 'COMMISSION',
}

export enum EPaymentCurrency {
  TON = 'TON',
}
