import { IPagination } from 'lc-interfaces';

export const hasNextPage = ({ page_index, page_size, count }: IPagination) => {
  return page_index * page_size < count;
};

export const hasPreviousPage = ({
  page_index,
  page_size,
  count,
}: IPagination) => {
  return page_index * page_size < count;
};

export const nextPage = ({ page_index }: IPagination) => {
  return page_index + 1;
};
