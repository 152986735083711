import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, NgModule, Type } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';

import { MatButtonModule } from '@angular/material/button';
import { MatBottomSheet, MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';

import { TranslocoModule, TranslocoService } from '@jsverse/transloco';

import { InViewportAction } from 'ng-in-viewport';

import { BehaviorSubject } from 'rxjs';
import { register } from 'swiper/element/bundle';

import { fromNano, toNano } from '@ton/core';

import { BaseBottomSheet } from 'app/client/modules/_base/bottom-sheet/base.bottom-sheet';
import { BaseDialog } from 'app/client/modules/_base/dialog/base.dialog';
import { BaseSpinner } from 'app/client/modules/_base/spinner/base.spinner';

import { AuctionService } from 'app/client/modules/auction/auction.service';
import { CommunityFriendService } from 'app/client/modules/community/modules/friend/friend.service';
import { CommunityReferralService } from 'app/client/modules/community/modules/referral/referral.service';
import { NotificationService } from 'app/client/modules/notification/notification.service';
import { PaymentService } from 'app/client/modules/payment/payment.service';
import { TonService } from 'app/client/modules/payment/ton.service';
import { UserService } from 'app/client/modules/user/user.service';

import { hasNextPage, hasPreviousPage, nextPage, telegramUserReferralUrl } from 'lc-helpers';

import {
  EPaymentStatus,
  EPaymentType,
  ESocialNetworkType,
  IModalContext,
  IPagination,
  IQuery,
  IUser,
} from 'lc-interfaces';

const MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  ScrollingModule,

  MatBottomSheetModule,
  MatButtonModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatToolbarModule,
  MatListModule,
  MatSidenavModule,
  MatExpansionModule,
  MatSelectModule,
  MatSnackBarModule,

  TranslocoModule,

  BaseSpinner,
];

register();

@NgModule({
  imports: MODULES,
  exports: MODULES,
})
export class BaseModule {}

@Component({
  selector: 'lc-base-helper',
  standalone: true,
  imports: [BaseModule],
  template: ``,
})
export class BaseComponent {
  protected readonly destroyRef = inject(DestroyRef);

  protected readonly activatedRoute = inject(ActivatedRoute);
  protected readonly router = inject(Router);

  protected readonly bottomSheet = inject(MatBottomSheet);
  protected readonly dialog = inject(MatDialog);
  protected readonly snackBar = inject(MatSnackBar);

  protected readonly translocoService = inject(TranslocoService);

  public readonly auctionService = inject(AuctionService);
  public readonly communityFriendService = inject(CommunityFriendService);
  public readonly communityReferralService = inject(CommunityReferralService);
  public readonly notificationService = inject(NotificationService);
  public readonly paymentService = inject(PaymentService);
  public readonly tonService = inject(TonService);
  public readonly userService = inject(UserService);

  public readonly users$: BehaviorSubject<IUser[]> = new BehaviorSubject<IUser[]>([]);

  protected query: IQuery = {};

  public pagination: IPagination = {
    page_index: 0,
    page_size: 0,
    count: 0,
  };

  public readonly hasNextPage = hasNextPage;
  public readonly hasPreviousPage = hasPreviousPage;
  public readonly nextPage = nextPage;

  public readonly telegramUserReferralUrl = telegramUserReferralUrl;

  public fromNano = fromNano;
  public toNano = toNano;

  public readonly EPaymentType = EPaymentType;
  public readonly EPaymentStatus = EPaymentStatus;
  public readonly ESocialNetworkType = ESocialNetworkType;

  // Just for extends
  protected find(reset: boolean = false) {}

  handleResetFind() {
    this.query.search = null;

    this.pagination.page_index = 1;
  }

  handleNextPage(event: InViewportAction): void {
    if (hasNextPage(this.pagination) && event.visible) {
      this.query.page_index = nextPage(this.pagination);

      this.find();
    }
  }

  openBottomSheet<T, D = {}>(component: Type<T>, context?: IModalContext<D>) {
    return this.bottomSheet.open(BaseBottomSheet, {
      data: {
        component,
        context,
      },
    });
  }

  openDialog<T, D = {}>(component: Type<T>, context?: IModalContext<D>) {
    return this.dialog.open(BaseDialog, {
      data: {
        component,
        context,
      },
    });
  }
}
