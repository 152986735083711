import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import {
  INotification,
  IQuery,
  IResponseData,
  IResponsePagination,
  TResponsePagination,
} from 'lc-interfaces';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  protected readonly http = inject(HttpClient);

  public find(query: IQuery): Observable<TResponsePagination<INotification[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http
      .get<
        IResponsePagination<INotification[]>
      >(`@api/notifications`, { params })
      .pipe(map((response) => response.data));
  }

  public findOneById(notification_id: string): Observable<INotification> {
    return this.http
      .get<
        IResponseData<INotification>
      >(`@api/notifications/notification/${notification_id}`)
      .pipe(map((response) => response.data));
  }

  public remove(notification_id: string) {
    return this.http.delete<IResponseData<INotification>>(
      `@api/notifications/notification/${notification_id}`,
    );
  }
}
