import { HttpClient, HttpParams } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from '@angular/router';

import { TranslocoService } from '@jsverse/transloco';

import { BehaviorSubject, map, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { IQuery, IResponseData, IResponsePagination, IUser, TResponsePagination } from 'lc-interfaces';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  protected readonly http = inject(HttpClient);

  protected readonly translocoService = inject(TranslocoService);

  public user!: IUser;
  public readonly user$: BehaviorSubject<IUser | null> = new BehaviorSubject<IUser | null>(null);

  protected _updateMe(user: IUser) {
    this.user = user;
    this.user$.next(user);

    if(user.language_code !== this.translocoService.getActiveLang()) {
      this.translocoService.setActiveLang('en')
    }

    return user;
  }

  public _me(): Observable<IUser> {
    return this.http
      .get<IResponseData<IUser>>(`@api/users/user/me`)
      .pipe(map(response => this._updateMe(response.data)));
  }

  public find(query: IQuery): Observable<TResponsePagination<IUser[]>> {
    const params = new HttpParams({ fromObject: { ...query } });

    return this.http.get<IResponsePagination<IUser[]>>(`@api/users`, { params }).pipe(map(response => response.data));
  }

  public findOneById(user_id: string): Observable<IUser> {
    return this.http.get<IResponseData<IUser>>(`@api/users/user/${user_id}`).pipe(map(response => response.data));
  }

  public updateMeWallet(userDTO: { address: string }): Observable<IUser> {
    return this.http
      .patch<IResponseData<IUser>>(`@api/users/user/me/wallet`, userDTO)
      .pipe(map(response => this._updateMe(response.data)));
  }
}

export const hasAuth: CanActivateFn = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const router = inject(Router);
  const userService = inject(UserService);

  const isSplashPage = state.url.includes('splash');

  return userService.user$.pipe(
    map(user => {
      if (!isSplashPage && !user) {
        void router.navigate(['/splash']);

        return false;
      }

      return isSplashPage || !!user;
    }),
    catchError(() => {
      // void router.navigate(['/splash']);

      return of(false);
    })
  );
};
